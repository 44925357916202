import Button from 'components/Button';
import React from 'react';
import ServiceBanner from 'assets/images/services-banner.jpg';
import ApplyNowBanner from 'assets/images/wtbapoi.jpg';
import styled from 'styled-components';
import media from 'utils/media';
import { useNavigate } from 'react-router-dom';

const DividedSection = () => {
  const navigate=useNavigate();
  return (
    <Section>
    <div>
      <span data-aos="fade-up">Know more about
        our services</span>
      <Button value={'All Services'} onClick={()=>navigate("/services")}/>
    </div>
    <div>
      <span data-aos="fade-up">Want to be a part
        of Inspeero?</span>
      <Button value={'Apply now'} onClick={()=>navigate("/contact")}/>
    </div>
  </Section>
  )
}

export default DividedSection;

const Section = styled.div`
  display: flex;
  flex-direction: column-reverse;
  min-height:60vh;
  div{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    font-weight: 700;
    font-family: 'Raleway';
    font-size: 2rem;
    line-height: 1.2;
    aspect-ratio: 1.3;
    span{
      width: 50%;
      text-align: center;
    }
    &:first-child{
      background:url(${ServiceBanner});
      background-size: cover;
    }
    &:last-child{
      background:url(${ApplyNowBanner});
      background-size: cover;
    }
  }
  @media ${media.tablet} {
    flex-direction: row;
  }
`;
