import React from 'react'
import styled, { keyframes } from 'styled-components';
import media from 'utils/media';
import loadimage from 'assets/svgs/loadingimg.svg';


const CustomLoader = () => {
    return (
        <Loadingspace>
            <img src={loadimage} alt="loadingimage" />
        </Loadingspace>
    )
}

export default CustomLoader;



const flipAnimation = keyframes`
  0% {
    transform:translateZ(100px) rotateY(0deg);
    -webkit-transform:translateZ(100px) rotateY(0deg);
  }
  50% {
    transform:translateZ(100px) rotateY(180deg);
    -webkit-transform:translateZ(100px) rotateY(180deg);
  }
  100% {
    transform:translateZ(100px) rotateY(0deg);
    -webkit-transform:translateZ(100px) rotateY(0deg);
  }
`;

const Loadingspace = styled.div`
display: flex;
justify-content: center;
height:100vh;
opacity: 1;
transition: opacity 1s ease-in-out;
-webkit-transition: opacity 1s ease-in-out;
position: fixed;
width: 100%;
top: 0;
left: 0;
height: 100%;
justify-content: center;
align-items: center;
background-color: #fff;
z-index: 9999;
opacity: 1;
-webkit-perspective: 1000;
perspective: 1000;
-webkit-transform-style: flat;
transform-style: flat;
img{
    width:15%;
    animation: ${flipAnimation} 1s infinite;
    -webkit-animation: ${flipAnimation} 1s infinite;
    /* -webkit-backface-visibility: hidden;
    backface-visibility: hidden; */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-transform-origin: center;
    transform-origin: center;
}
  @media ${media.tablet} {
  img{
    width:5%;
  }
  }
&.hidden {
  opacity: 0;
  pointer-events: none;
  z-index: -9999;
}

`;