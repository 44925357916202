import Button from 'components/Button';
import PopupComponent from 'components/popup-component/PopupComponent';
import GradientHeadingSection from 'components/common-sections/GradientHeadingSection';
import FollowUsSection from 'components/contactus-sections/FollowUsSection';
import OurOfficesSection from 'components/contactus-sections/ourOfficesSection';
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import media from 'utils/media';
import { SendMail } from 'utils/sendEmail';
import { saveLeads } from 'utils/saveLeads';
import HCaptcha from '@hcaptcha/react-hcaptcha';
const ContactUsPage = () => {
    const [captchaVerified,setCaptchaVerified]=useState(false);
    const phoneRegExp = /^[6-9]\d{9}$/
    
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please provide your email.')
            .email('This email is not valid.'),
        name: Yup.string().required('Please provide your name.'),
        organisation: Yup.string().required('Please provide your organization.'),
        mobileno: Yup.string().required('Please provide your contact number.').matches(phoneRegExp, 'Phone number is not valid'),
        purpose:Yup.string()
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const [showPopup, setShowPopup] = useState(false);

    const OpenPopup = () => {
        setShowPopup(true)

    }
    const onVerifyCaptcha=(token)=>{
        setCaptchaVerified(true);
    }

    useEffect(() => {
        if (showPopup) {
            [document.getElementById('main-route').style.overflow, document.getElementById('main-route').style.maxHeight] = ['hidden', '100vh'];
        } else {
            [document.getElementById('main-route').style.overflow, document.getElementById('main-route').style.maxHeight] = ['auto', 'none'];
        }
    }, [showPopup]);
    const HandleContactForm = (formValues) => {
        saveLeads(formValues);
        SendMail(formValues);
        reset();
    }
    return (
        <>
            <PopupComponent
                showPopup={showPopup}
                setShowPopup={setShowPopup}
            />

            <FormSection>
                <HeadingSection data-aos="fade-right">
                    <GradientHeadingSection
                        Title={'Contact us'}
                        GradientText={'Get in touch'}
                        Primary={'With us'}
                        Secondary={"If you think about how to change the world using your talents, contact Inspeero about an exciting career. We have offices across the U.S. and in Mumbai, India."}
                    />
                </HeadingSection>
                <Form onSubmit={handleSubmit(HandleContactForm)} method='' data-aos="fade-left" id='contactus-form'>
                    <h1>Get in touch with us</h1>
                    <div>
                        <label>Name</label>
                        <input type='text' name="name"  {...register('name')} />
                        <span className="invalid-feedback">{errors.name?.message}</span>
                    </div>
                    <div>
                        <label>Organization</label>
                        <input type='text' name="organisation"  {...register('organisation')} />
                        <span className="invalid-feedback">{errors.organisation?.message}</span>
                    </div>
                    <div>
                        <label>Email</label>
                        <input type='text' name="email"  {...register('email')} />
                        <span className="invalid-feedback">{errors.email?.message}</span>
                    </div>
                    <div>
                        <label>Contact Number</label>
                        <input type='text' name="mobileno"  {...register('mobileno')} />
                        <span className="invalid-feedback">{errors.mobileno?.message}</span>
                    </div>
                    <div>
                        <label>Description</label>
                        <textarea name="purpose"  {...register('purpose')} />
                    </div>
                    <HCaptcha
                     sitekey="afdd7015-b973-49e7-962a-4dd94d24cc7a"
                     onVerify={onVerifyCaptcha}
                     />
                    <Button value={'Submit'} inversed={true} style={{ marginTop: '2rem' }} disabled={!captchaVerified} removeAnimation={true}/>
                </Form>
            </FormSection>
            <GradientBlock>
                <h1>You can also book an appointment by clicking <a onClick={OpenPopup}>
                    here
                </a></h1>
            </GradientBlock>
            <OurOfficesSection />
            {/* <FollowUsSection /> */}
        </>
    )
}

export default ContactUsPage;

const GradientBlock = styled.div`
margin-top: 5%;
 min-height: 10vh;
 padding: 2%;
 background: linear-gradient(90deg, #38B450 27.89%, #12BAC6 82.89%);
 display: grid;
 place-items:center;
 text-align: center;
 h1{
    font-size: 2.1rem;
    color: var(--white);
    font-weight: 600;
 }
 a{
    color:var(--white);
    text-decoration: underline;
    text-underline-offset: 0.3rem;
 }
`;

const HeadingSection = styled.div`
  padding: 1% 8%;
  display: flex;
  /* align-items: flex-start; */
  flex-direction: column;
  /* justify-content: flex-start; */
  gap:1rem;

  h2{
    font-size: 3.5rem;
  }
  p{
    font-size: 1.8rem;
    line-height: 2rem;
  }
`;

const Form = styled.form`
padding: 2rem;
margin: 1rem 2rem;
flex:1;
border-radius: 0.5rem;
box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
-webkit-box-shadow:0px 1px 16px rgba(0, 0, 0, 0.12);
-moz-box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
h1{
    margin-bottom: 5%;
}
div{
    margin: 1.5rem 0;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    label{
        font-size: 1.25rem;
        font-weight: 700;
    }
    input,textarea{
        font-family: 'Open Sans';
        padding: 2%;
        background-color: var(--lightgray);
        outline:none;
        border:none;
        font-size:large;
        font-weight: 600;
    }
    textarea{
        resize: vertical;
        padding:1% 2%;
        height: 10vh;
    }
}
.invalid-feedback{
    font-family: 'Open Sans';
    font-size: 0.9rem;
    font-weight:500;
    color: red;
}
@media ${media.laptop} {
    margin: 1rem 4rem;
  }
`;

const FormSection = styled.div`
display: flex;
margin-top:8%;
flex-direction: column;
gap: 2rem;
& > div{
    flex:1;
}
@media ${media.laptop} {
    flex-direction: row;
    gap: 0.5rem;
  }
`;

export const GradientH1 = styled.h1`
font-size: 4rem;
font-weight: 700;
background: -webkit-linear-gradient(180deg, #11baa6, #30b66f);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;