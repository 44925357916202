import React from 'react'
import styled from 'styled-components';
import WorkHeaderSection from './WorkHeaderSection';
import media from 'utils/media';
import WorkCard from 'components/workpage-sections/WorkCard';
import dummyImg from 'assets/images/antwork.png'
import { workData } from 'data/workdata';


const WorkPage = () => {
    return (
        <Section>
            <WorkHeaderSection />
            <WorkPortfolio>
                {
                    workData.map((work)=>{
                        return(
                        <WorkCard details={work}/>
                        )
                    })
                }
            </WorkPortfolio>
        </Section>
    )
}

export default WorkPage;

const Section = styled.section`
    min-height: 100vh;
`;

const WorkPortfolio = styled.div`
padding: 2.5% 5.5%;
display: flex;
flex-wrap: wrap;
gap: 5.5rem;
justify-content: flex-start;
flex-direction: column;

@media ${media.tablet} {
    padding: 3.5% 2.5%;
    flex-direction: row;
    gap: 2.5rem;
  }
`;