import Swal from "sweetalert2";
import { Email } from "./smtp";
import { SMTPClient } from "smtp-client";
// import {nodemailer} from "nodemailer";
// const nodemailer = require("nodemailer");

export const SendMail = (payload) => {
  console.log("Sending...");
  Email.send({
    Host: "smtp.elasticemail.com",
    Username: "noreply@insp.com",
    port: 2525,
    Password: "6FBBC2DEC67E3C73197316B28C87180A3C93",
    To: "pathik@inspeero.com",
    From: "dpatil@inspeero.com",
    Subject: "Got new Contact request",
    Body: `
    <html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
    </head>
    <body>
        <Container class="container">
            <div>
                <h1 style={{ textAlign: 'left' }}>Someone has contacted Inspeero Technologies</h1>
                <b>The details are given below<br /></b>


                <div>
                    <br />
                    Name:${payload.name}<br /><br />
                    Email Address:${payload.organisation}<br /><br />
                    Organisation:${payload.email}<br /><br />
                    Contact number : ${payload.contact_no} <br/>
                    Description: ${payload.purpose || 'No description given'}
                </div>
            </div>
        </Container>
    </body>
</html>
    `
  }).then((message) => {
    if(message==='OK'){
      Swal.fire(
        'Thank you For contacting us!',
        'We will reach out to you shortly',
        'success'
      )
    }else{
      Swal.fire(
        'Something went wrong!',
        'Sorry for Inconvinience, please try after some time',
        'error'
      )
    }
    // console.log("mail sent successfully", message)
  });
};

export const sendMailClient = () => {
  let s = new SMTPClient({
    host: "smtp.elasticemail.com",
    port: 587
  });

  (async function () {
    await s.connect();
    await s.greet({ hostname: "smtp.elasticemail.com" }); // runs EHLO command or HELO as a fallback
    await s.authPlain({
      username: "email-host",
      password: "pass"
    }); // authenticates a user
    await s.mail({ from: "email-host" }); // runs MAIL FROM command
    await s.rcpt({ to: "..." }); // runs RCPT TO command (run this multiple times to add more recii)
    await s.data("Hola mundo mail source"); // runs DATA command and streams email source
    await s.quit(); // runs QUIT command
  })().catch(console.error);
};

// export const sendNodemailer = () => {
//   let transport = nodemailer.createTransport({
//     host: "smtp.mailtrap.io",
//     port: 2525,
//     auth: {
//       user: "put_your_username_here",
//       pass: "put_your_password_here"
//     }
//   });
//   const message = {
//     from: "elonmusk@tesla.com", // Sender address
//     to: "to@email.com", // List of recipients
//     subject: "Design Your Model S | Tesla", // Subject line
//     text: "Have the most fun you can in a car. Get your Tesla today!" // Plain text body
//   };
//   transport.sendMail(message, function (err, info) {
//     if (err) {
//       console.log(err);
//     } else {
//       console.log(info);
//     }
//   });
// };
