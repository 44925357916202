import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';
import Blacklogo from "assets/svgs/il-black.svg";
import sidebarbg from 'assets/images/modal-bg.png';
import SideInfo from './SideInfo';
import gmailLogo from 'assets/images/logos_google-gmail.svg';
import telephoneLogo from 'assets/images/telephone-ion.svg';
import ScheduleSection from './ScheduleSection';


const MeetScreen = ({ selectedSchedule, setSelectedSchedule, setSubmitted }) => {
    return (
        <Section>
            <SideSection>
                <LogoContainer>
                    <LogoImage src={Blacklogo} alt="Logo" />
                </LogoContainer>
                <ContactSection className='sidesection--contact'>
                    <SideInfo
                        heading={'Mail Us'}
                        info={'Our friendly team is here to help'}
                        logo={gmailLogo}
                        linkType={'mailto'}
                        link={'info@inspeero.com'}
                    />
                    <SideInfo
                        heading={'Phone'}
                        info={'Mon to Fri 9:30 am to 5:30 pm.'}
                        logo={telephoneLogo}
                        linkType={'tel'}
                        link={'+91 9137761928'}
                    />
                </ContactSection>
            </SideSection>
            <MainSection>
                <ScheduleSection
                    selectedSchedule={selectedSchedule}
                    setSelectedSchedule={setSelectedSchedule}
                    setSubmitted={setSubmitted}
                />
            </MainSection>
            <ContactSection className='outer--contact'>
                <SideInfo
                    heading={'Mail Us'}
                    info={'Our friendly team is here to help'}
                    logo={gmailLogo}
                    linkType={'mailto'}
                    link={'info@inspeero.com'}
                />
                <SideInfo
                    heading={'Phone'}
                    info={'Mon to Fri 9:30am to 5:30pm.'}
                    logo={telephoneLogo}
                    linkType={'tel'}
                    link={'+91 9137761928'}
                />
            </ContactSection>
        </Section>
    )
}

export default MeetScreen;

const ContactSection = styled.div`
display: flex;
justify-content: space-evenly;
@media ${media.tablet} {
    justify-content: flex-start;
    flex:1 1 75%;
    flex-direction: column;
}
`;

const LogoContainer = styled.div`
display: flex;
display:flex;
min-height: 5vh;
padding: 2% 20%;
justify-content: center;
align-items: center;
/* border-bottom: 0.6px solid grey; */
box-shadow: -1px 1px 2px -1px rgba(0,0,0,0.1);
-webkit-box-shadow: -1px 1px 2px -1px rgba(0,0,0,0.1);
-moz-box-shadow: -1px 2px 1px -1px rgba(0,0,0,0.1);
flex:1 1 25%;

@media ${media.tablet} {
    /* min-height: 15vh; */
    justify-content: flex-start;
    align-items: center;
    padding: 0 10%;
    }
`;

const LogoImage = styled.img`
  height: 70%;
  width: 70%;
  @media ${media.tablet} {
      height: 50%;
      width: 50%;
  }
`;

const Section = styled.div`
height: 100%;
display: flex;
flex-direction: column;
overflow: auto;

.outer--contact{
    background-image: url(${sidebarbg});
    display: flex;
        @media ${media.tablet} {
            display: none;
        }
}

@media ${media.tablet} {
            overflow: hidden;
            flex-direction: row;
        }
`;

const SideSection = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(${sidebarbg});

    .sidesection--contact{
        display: none;
        @media ${media.tablet} {
            display: flex;
        }
    }

    /* backdrop-filter: blur(9px); */
    @media ${media.tablet} {
        flex:1 1 25%;
        }
`;

const MainSection = styled.div`
flex: 1 1 75%;
`;
