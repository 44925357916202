import ProjectBannerSection from 'components/common-sections/ProjectBannerSection';
import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';
import modulusBg from 'assets/images/modulus-bg.svg';
import modulusCover from 'assets/images/modulus-cover.svg';
import modulusLogo from 'assets/images/modulus-logo.svg';
import modulus1 from 'assets/images/modulus-1.svg';
import modulus3 from 'assets/images/modulus-3.svg';
import modulus4 from 'assets/images/modulus-4.svg';
import ContributionIntro, { DetailsSection } from 'pages/antworks/ContributionIntro';


const ModulusPage = () => {
  return (
    <PageContainer>
            <ProjectBannerSection
        title={'Modulus'}
        bgImg={modulusBg}
        image={modulusCover}
        logo={modulusLogo}
        logoWidth={'50%'}
        slogan={'State of the art cryptocurrency exchange platform'} 
      />
            <Section>
            <ContributionIntro
          heading={'Modulus'}
          paragraph={'Since 1997, Modulus has provided advanced technology products and services to clients including The NASDAQ Stock Market, J.P. Morgan Chase, Bank of America to name a few.'}
          contributionArray={['Mobile App Development', 'White label Development', 'Strategy + Innovation']}
        />
        <Mobileimg>
          <img src={modulus1} alt='group of mobiles' data-aos="fade-zoom-in" />
        </Mobileimg>
        <ContainerWithPadding>
          <DetailsSection heading={'Realtime Trading at lightining speed'} paragraph={'Modulus allows its users to buy and sell cryptocurrencies in realtime at a very quick pace. We used latest technologies and a modern software architecture to ensure that trades happen smoothly without any hiccups.'} />
        </ContainerWithPadding>
        <Mobileimg fullSize>
          <img src={modulus3} alt='group of mobiles' data-aos="fade-zoom-in"/>
        </Mobileimg>
        <ContainerWithPadding>
          <DetailsSection heading={'Tight integrations for better security'} paragraph={'Every finance related app needs the top of the line security and that’s what we’ve done. The app requires authentication to process any buy/sell trades via Google Authenication and in-built authentication logic that ensures maximum safety.'} />
        </ContainerWithPadding>
        <Mobileimg>
          <img src={modulus4} alt='group of mobiles' data-aos="fade-zoom-in" />
        </Mobileimg>
        <ContainerWithPadding>
          <DetailsSection style={{ textAlign: 'center' }} heading={'One for all white label solution'} paragraph={'Modulus’s vision was to create a white-label product that would spawn several other crypto apps using their technology and data-architecture. This allowed several apps to be released on play stores worldwide.'} />
        </ContainerWithPadding>
        {/* <ImagePlusDesc
          Img={mobImg}
          heading={'AntPulse'}
          description={'AntPulse is an automated employee engagement and performance management product envisoned by AntWorks for internal purposes to track behaviours and attitudes of an employee in relation to its key metrics.The app is available to download on Android & iOS.'}
        />
        <ImagePlusDesc
          Img={LaptopScreenShot}
          heading={'Powerful Backend Integrations'}
          description={'We were tasked with creating a state of the art back-end architecture for formulating and automating surveys and to define metrics as per employee job band and role.'}
          reversed={true}
          Scale={2}
        />
        <div className='big-img--container'>
          <DetailsSection heading={'Thorougly refined, heavily automated'} paragraph={'Our excellent team thoroughly stress tested the application and the API’s that we developed to communicate with the app but, a lot of what makes this app comes from the automation scripts that we put into place. These scripts trigger on specific actions in the central database system which then creates a ripple effect where the app collects data, providing real time reports of an employee’s lifecycle.'} />
          <img src={groupImage} alt='group-of-mobiles' data-aos="fade-zoom-in" />
        </div> */}
      </Section>
      {/* <CaseStudiesSection/> */}
    </PageContainer>
  )
}

export default ModulusPage;

const ContainerWithPadding = styled.div`
  padding:0 5%;
  margin: 10% 0;
  @media ${media.tablet} {

  }
`;

const PageContainer = styled.section`
    overflow-x: hidden;
  .main-hero--section{
    min-height: unset;
    padding-top:2%;
  }
  @media ${media.tablet} {
    .main-hero--section{
      padding-top:0;
      min-height: 100vh;
    }
  }

`;
const Section = styled.div`
  padding:5% 0;
  min-height: 100vh;

  .big-img--container{
    
    display: flex;
    flex-direction: column-reverse;
    /* min-height: 60vh; */
    gap: 1.5rem;
    > div{
      padding:0 5%;
    }
    @media ${media.laptopL} {
      flex-direction: column;
    }
  }

`;

const Mobileimg = styled.div`
display: flex;
justify-content: center;
margin: 8% 0;
& img {
    width: ${props => props.fullSize ? '100%' : '60%'};
    height: ${props => props.fullSize ? '100%' : '60%'};
  }
@media ${media.tablet} {
  margin: ${props => props.fullSize ? '0 5%' : '0% 13%'};
  }

`;