import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './header';
import HomePage from '../pages/homepage';
import Footer from './footer';
import ServicesPage from 'pages/servicespage';
import ContactUsPage from 'pages/contactuspage';
import WorkPage from 'pages/workpage';
import WnsPage from 'pages/wns';
import AntworksPage from 'pages/antworks';
import ModulusPage from 'pages/modulus';
import PrivacyPolicyPage from 'pages/privacypolicypage';
import NotFoundPage from 'pages/404Page';
import CustomLoader from './CustomLoader';

const AnimatedRoutes = () => {
  const location = useLocation();
  const { pathname } = useLocation();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("aos-animate");
        } else {
          entry.target.classList.remove("aos-animate");
        }
      });
    });
    document.querySelectorAll("[data-aos]").forEach((aosElem) => {
      observer.observe(aosElem);
    });
    return () => {
      document.querySelectorAll("[data-aos]").forEach((aosElem) => {
        observer?.unobserve?.(aosElem);
      });
    };
  }, []);

  useEffect(() => {
    // ReactGA.event({'category':'category',action:'action',label:'label'})

    setloading(true);

    setTimeout(() => {
      setloading(false);

    }, 2000);

    return () => {
      setloading(false);
    }
  }, [pathname])




  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <>
      {loading
        ?
        (
          <CustomLoader />
        )
        :
        (
          <div id="main-route">
            <Header />
            <ScrollToTop />
            <Routes location={location} key={location.pathname}>
              <Route exact path="/" index element={<HomePage />} />
              <Route exact path="/work" element={<WorkPage />} />
              <Route exact path="/services" element={<ServicesPage />} />
              <Route exact path="/contact" element={<ContactUsPage />} />
              <Route exact path="/wns" element={<WnsPage />} />
              <Route exact path="/antworks" element={<AntworksPage />} />
              <Route exact path="/modulus" element={<ModulusPage />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route exact path="/terms-and-conditions" element={<PrivacyPolicyPage />} />
              <Route exact path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
          </div>
        )
      }
    </>

  )
}

export default AnimatedRoutes;