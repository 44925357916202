import React, { useEffect } from 'react'
import styled from 'styled-components';
import gmailLogo from 'assets/images/logos_google-gmail.svg';
import tickmarkIcon from 'assets/images/tickmark-icon.png';
import media from 'utils/media';

const ScheduleCard = ({ logo, title, details, selectedSchedule, setSelectedSchedule }) => {

    return (
        <CardBody onClick={() => setSelectedSchedule(title)}>
            <Bluedot selected={selectedSchedule === title} >
                <img src={tickmarkIcon} style={{ height: '70%', width: '70%', transform: 'rotate(20deg) translate(-2px,-4px)' }} />
            </Bluedot>
            <div className='contact--icon'>
                <IconContainer>
                    <img src={logo || gmailLogo} />
                </IconContainer>
            </div>
            <div className='contact--details'>
                <H1>{title || 'Business Consultation'}</H1>
                <P>{details || 'I need a website for my product'}</P>
            </div>
        </CardBody>
    )
}

export default ScheduleCard;
const Bluedot = styled.span`
height: 1em;
aspect-ratio: 1;
/* width: 1vmax; */
border-radius: 40px;
border: 1.5px solid var(--cyan);
background-color: ${({ selected }) => (selected ? 'var(--cyan)' : "transparent")};
transition: background-color 0.3s ease-in-out;
position: absolute;
right: 2%;
top: 5%;

@media ${media.tablet} {
    right: 8px;
    top: 8px;
}
`;

const CardBody = styled.div`
      position: relative;
      padding: 3% 2%;
      color: var(--darkgray);
      width: calc(33.33% - 20px);
      /* aspect-ratio: 1.2; */
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
      border-radius: 7px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media ${media.tablet} {
          flex-direction: row;
          align-items: start;
          justify-content: flex-start;
          gap: 1rem;
      }
      
      &:hover{
          box-shadow: 0 8px 28px rgba(0,0,0,0.25), 0 5px 10px rgba(0,0,0,0.22);
      }
      
      @media (max-width: 768px) {
          aspect-ratio: 2.1;
          width: 100%;
          margin-right: 0;
        }
        
    .contact--icon{
    /* padding: 2%; */
    text-align: center;
    display: flex;
    justify-content: center;
}

    .contact--details{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding:0 4%;
}
@media ${media.tablet} {
    .contact--details{
        gap: 0.5rem;
        /* border: 1px solid black; */
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}


`
const IconContainer = styled.div`
border: 1px solid rgba(0, 0, 0, 0.3);
border-radius: 11.25px;
height: 2.4em;
background-color: white;
aspect-ratio: 1.2;
display: grid;
place-items: center;
img{
    width: 60%;
    height: 60%;
}
@media ${media.tablet} {
    height: 2em;
}
`;

const H1 = styled.h1`
    font-weight: 600;
    font-size: 1.5rem;
    @media ${media.tablet} {
        text-align: center;
        font-size: 0.9rem;
    }
`;
const P = styled.p`
font-family: 'Open Sans';
font-size: 1rem;
font-weight: 400;
/* word-break: break-all; */
@media ${media.tablet} {
    font-size: 0.8rem;
    text-align: center;
    }
`