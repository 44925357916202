import React from 'react';
import styled from 'styled-components';
import antWorkbg from 'assets/images/antwork-bg.svg';
import antWorkLogo from 'assets/images/antwork-logo.png';
import antWorkImage from 'assets/images/antwork-image.png';
import ContributionIntro, { DetailsSection } from './ContributionIntro';
import ImagePlusDesc from './ImagePlusDesc';
import mobImg from 'assets/images/antwork-mobile-screenshot.svg';
import LaptopScreenShot from 'assets/images/antwork-laptop-screenshot.png';
//  import LaptopScreenShot from 'assets/images/antwork-laptop.png';
import groupImage from 'assets/images/ant-work-group-of-mobiles.png';
import media from 'utils/media';
import ProjectBannerSection from 'components/common-sections/ProjectBannerSection';




const AntworksPage = () => {
  return (
    <PageContainer>
      <ProjectBannerSection
        title={'AntPulse'}
        bgImg={antWorkbg}
        image={antWorkImage}
        logo={antWorkLogo}
        logoWidth={'50%'}
        slogan={'Making employee lifecycle powerful with AI'}
      />
      {/* <ShowoffBlock
        bgImg={antWorkbg}
        logo={antWorkLogo}
        image={antWorkImage}
        link={'/antworks'}
        className={'main-hero--section'}
      /> */}
      <Section>
        <ContributionIntro />
        <ImagePlusDesc
          Img={mobImg}
          heading={'AntPulse'}
          description={'AntPulse is an automated employee engagement and performance management product envisoned by AntWorks for internal purposes to track behaviours and attitudes of an employee in relation to its key metrics.The app is available to download on Android & iOS.'}
          Scale={1.2}
        />
        <ImagePlusDesc 
          Img={LaptopScreenShot}
         // imagestyle={background-color: black;}
          fullSize
          imagestyle={{transform:'transletX'}}
          // style={padding}
          heading={'Powerful Backend Integrations'}
          description={'We were tasked with creating a state of the art back-end architecture for formulating and automating surveys and to define metrics as per employee job band and role.'}
          reversed={true}
          Scale={2.8}
            
        />
        <div className='big-img--container'>
          <DetailsSection heading={'Thorougly refined, heavily automated'} paragraph={'Our excellent team thoroughly stress tested the application and the API’s that we developed to communicate with the app but, a lot of what makes this app comes from the automation scripts that we put into place. These scripts trigger on specific actions in the central database system which then creates a ripple effect where the app collects data, providing real time reports of an employee’s lifecycle.'} />
          <img src={groupImage} alt='group-of-mobiles' data-aos="fade-zoom-in" />
        </div>
      </Section>
      {/* <CaseStudiesSection/> */}


    </PageContainer>
  )
}

export default AntworksPage;

const PageContainer = styled.section`
  overflow-x: hidden;
  .main-hero--section{
    min-height: unset;
    padding-top:2%;
  }
  @media ${media.tablet} {
    .main-hero--section{
      padding-top:0;
      min-height: 100vh;
    }
  }

`;
const Section = styled.div`
  padding:5% 0;
  min-height: 100vh;

  .big-img--container{
    
    display: flex;
    flex-direction: column-reverse;
    /* min-height: 60vh; */
    gap: 1.5rem;
    > div{
      padding:0 5%;
    }
    @media ${media.laptopL} {
      flex-direction: column;
    }
  }

`;