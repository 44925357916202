export const privacyPolicy=[
    {
        desc:'Your privacy is important to us. It is Inspeero Technologies LLP’s policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://www.inspeero.com" target="_blank">https://www.inspeero.com</a>, and other sites we own and operate.'
    },
    {
        desc:'We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.'
    },
    {
        desc:'We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.'
    },
    {
        desc:'We don’t share any personally identifying information publicly or with third-parties, except when required to by law.'
    },
    {
        desc:'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.'
    },
    {
        desc:'You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.'
    },
    {
        desc:'Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.'
    },
    {
        desc:'This policy is effective as of 1 April 2020.'
    },
]

export const termsAndConditions=[
    {
        heading:'Terms',
        desc:'By accessing the website at <a href="https://www.inspeero.com" target="_blank">https://www.inspeero.com</a> you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.'
    },
    {
        heading:'Use License',
        desc:'Permission is granted to temporarily download one copy of the materials (information or software) on Inspeero Technologies LLP’s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not;<br/><br/>modify or copy the materials;<br/><br/>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);<br/><br/>attempt to decompile or reverse engineer any software contained on Inspeero Technologies LLP’s website;<br/><br/>remove any copyright or other proprietary notations from the materials; or <br/><br/> transfer the materials to another person or “mirror” the materials on any other server.<br/><br/>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Inspeero Technologies LLP at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.'
    },
    {
        heading:'Disclaimer',
        desc:'The materials on Inspeero Technologies LLP’s website are provided on an ‘as is’ basis. Inspeero Technologies LLP makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.<br/><br/>Further, Inspeero Technologies LLP does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.'
    },
    {
        heading:'Limitations',
        desc:'In no event shall Inspeero Technologies LLP or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Inspeero Technologies LLP’s website, even if Inspeero Technologies LLP or a Inspeero Technologies LLP authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.'
    },
    {
        heading:'Accuracy of materials',
        desc:'The materials appearing on Inspeero Technologies LLP’s website could include technical, typographical, or photographic errors. Inspeero Technologies LLP does not warrant that any of the materials on its website are accurate, complete or current. Inspeero Technologies LLP may make changes to the materials contained on its website at any time without notice. However Inspeero Technologies LLP does not make any commitment to update the materials.'
    },
    {
        heading:'Links',
        desc:'Inspeero Technologies LLP has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Inspeero Technologies LLP of the site. Use of any such linked website is at the user’s own risk.'
    },
    {
        heading:'Modifications',
        desc:'Inspeero Technologies LLP may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.'
    },
    {
        heading:'Governing Law',
        desc:'These terms and conditions are governed by and construed in accordance with the laws of MH and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.'
    },
]

export const refundPolicy=[
    {
        desc:'Our refund policy has been devised to define the situations under which Inspeero Technologies will provide a refund, the procedure for claiming a refund and the responsibility of Inspeero Technologies in circumstances resulting in such a claim. By registering for any of our services you are declaring that you accept and agree with all the terms and conditions outlined in the refund policy.'
    },
    {
        desc:'<b>Coverage & Scope</b><br/>Our refund policy covers the refunds by Inspeero Technologies, a IT Services firm based out of India.<br/>This policy does not have any application for companies which are not owned or controlled by Inspeero Technologies or for persons not employed or managed by Inspeero Technologies. This includes any third party service and / or product providers bound by contract and also, any third-party websites to which Inspeero Technologies’ website link.'
    },
    {
        desc:'<b>Filing a Complaint </b><br/>At Inspeero Technologies, we take every project with the final product in sight. It is as much our responsibility as the client’s. So, we believe that every effort should be made to reach a solution that is fully acceptable reciprocally in case of any situation where dissatisfaction related to services comes.<br/>Our final aim is to come to a mutually acceptable solution. Only if we are unable to reach a common ground with you after talks should a refund claim be filed.'
    },
    {
        desc:'<b>Eligibility for refund </b> <br/>At Inspeero Technologies, every project is important to us and we make sure that we handle each project with utmost care and professionalism. Our aim is to provide the results as per the terms and conditions and scope of services of the project proposal. However, despite every measure if the client is not satisfied with the results and chooses to go for the dispute resolution process, we consider refund requests as per the following terms in which our services and refund policy is very clearly explained:'
    },
    {
        desc:'<b>Full refund:</b><br/> In cases where the project has not been started or if the initial design style has not been approved. The Full Refund policy will be initiated and will take up to 60 Days to refund the amount minus all the fees and charges borne by Inspeero Technologies.'
    },
    {
        desc:'<b>Partial refund: </b> <br/>If there is a failure to deliver as per our agreed Terms and Conditions and Scope of Services. The partial refund will be in pro-rated basis of how much work is already done and how much is left based on the Scope of Services agreed upon at the start of the project.'
    },
    {
        desc:'<b>No refunds:</b><br/> If the project development has completed, there will be no refunds.'
    },
    {
        desc:'<b>NOTE: Inspeero Technologies’ delivery commitment is subject to:</b> <br/>- A project is not taken to be void unless the agreed upon payments are clear.<br/><br/>- Refund policy is not applicable if the required information for the successful completion of the project is not given to us at proper time. If there is delay or failure in completion of the project due to improper communication from client, it cannot be attributed to Inspeero Technologies.<br/><br/>- If the information provided by the client is incomplete and/or complete information regarding the project is not provided at the initiation of the project, Inspeero Technologies is not liable to follow its delivery or refund commitments.<br/><br/>- There is no provision for compensation for the delay of delivery under any conditions, until and unless there is an agreement signed with a penalty clause for delay in delivery.'
    },
    {
        desc:'<b>Limitation of Liability</b><br/> Inspeero Technologies’ liability is bound by the value of the chunk of the project (as per our proposal) which remains incomplete at a given point point of time. Inspeero Technologies is not obligated for losses due to the services provided/ not provided or the delay in the same at any point of time. The liability to refund holds only if the project has been cancelled by the client and such cancellation has been communicated to Inspeero Technologies in writing.'
    },
    {
        desc:'<b>Processing of Refunds</b><br/>The partial refunds will be processed within 60 business days of the date of cancellation and will be brought about using the method of payment agreed upon in the beginning of the project i.e. refund by check or refund by credit card or an alternative method agreeable to both the client as well as Inspeero Technologies.'
    },
    {
        desc:'<b>Limitation of Liability</b><br/> Inspeero Technologies’ liability is bound by the value of the chunk of the project (as per our proposal) which remains incomplete at a given point point of time. Inspeero Technologies is not obligated for losses due to the services provided/ not provided or the delay in the same at any point of time. The liability to refund holds only if the project has been cancelled by the client and such cancellation has been communicated to Inspeero Technologies in writing.<br/>Full refunds will also be processed and mailed within 60 business days of the date of cancellation and will be brought about using the method of payment agreed upon in the beginning of the project i.e. refund by check or refund by credit card or an alternative method agreeable to both the client as well as Inspeero Technologies.'
    },
    {
        desc:'<b>Changes </b><br/>Inspeero Technologies may at any time, without prior notice under its sole discretion, amend this policy from time to time. You are therefore requested to review this policy periodically. Your continued use of Inspeero Technologies services after any such amendments automatically implies your acceptance of the same thereof.'
    },
    {
        desc:'<b>If you have queries or suggestions regarding our refund policies kindly e–mail us at</b> <br/><br/><a href="mailto:support@inspeero.com" ><b>support@inspeero.com</b></a>'
    },
];






