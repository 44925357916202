export const saveLeads = async (payload) => {
  payload=JSON.stringify(payload);
  var requestOptions = {
    method: 'POST',
    body: payload,
    redirect: 'follow',
  };

  let res = await fetch("https://r0wt09lvp6.execute-api.us-east-1.amazonaws.com/saveLead", requestOptions)
  res = await res.json();
}