import uuImage from 'assets/svgs/business-consultation.svg';
import tcImage from 'assets/svgs/tc.svg';
import bcImage from 'assets/svgs/uiux-consultation.svg';
export const scheduleData = [
    {
        title: 'Fullstack Product Consultation',
        desc: 'Comprehensive end-to-end product consulting for successful development, launch, and management.',
        icon: uuImage,
        link:'https://timesync.novocall.co/t/inspeero/fpc',
        src:'https://meet.novocall.co/v1/widgets/calendar?id=6PdFnDNtJFI',
        appId:'AppWidgetButton6PdFnDNtJFI'
        
    },
    {
        title: 'Experience Design Consultation',
        desc: 'Enhance UX for engaging web/mobile apps.',
        icon: bcImage,
        link:'https://timesync.novocall.co/t/inspeero/uxd',
        src:'https://meet.novocall.co/v1/widgets/calendar?id=vg7elFyEZkU',
        appId:'AppWidgetButtonvg7elFyEZkU'
    },
    {
        title: 'Product Consultation',
        desc: 'Research, strategy, design, technology, execution, optimization.',
        icon: uuImage,
        link:'https://timesync.novocall.co/t/inspeero/prc',
        src:'https://meet.novocall.co/v1/widgets/calendar?id=K67oFaSkc4U',
        appId:'AppWidgetButtonK67oFaSkc4U'
    },
    {
        title: 'Tech Consultation',
        desc: 'Considerations for native, multi-platform, web, mobile, and database development.',
        icon: tcImage,
        link:'https://timesync.novocall.co/t/inspeero/tech',
        src:'https://meet.novocall.co/v1/widgets/calendar?id=z8OZh9R8QhI',
        appId:'AppWidgetButtonz8OZh9R8QhI',
    },
];