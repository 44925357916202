import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';
import dummyImg from 'assets/images/antwork.png';

const WorkCard = ({ details }) => {
    return (
        <Card data-aos="fade-up">
            <CardImgContainer>
            <CardImage src={details.image || dummyImg} />
            </CardImgContainer>
            <CardDescription>
                <h3>{details.title || 'WNS'}</h3>
                <h2>{details.info || 'Making employee lifecycle powerful with AI'}</h2>
                {/* <p>{details.detail || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam euismod fringilla urna vitae imperdiet. Suspendisse sodales neque id efficitur feugiat.'}</p> */}
            </CardDescription>
        </Card>
    )
}

export default WorkCard;

const CardImgContainer = styled.div`
    /* flex: 50%; */
    display: flex;
    overflow: hidden;
`;
const CardImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
    &:hover{
        transform: scale(1.1);
        -webkit-transform:scale(1.1);
    }
`;

const CardDescription = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  padding: 4% 8%;
  
  h3{
      /* flex:1; */
      font-family:'Open Sans',sans-serif;
      font-size: larger;
      font-weight: 400;
      color:rgba(23, 23, 23, 0.37);
      letter-spacing: 0.2em;
    }
    h2{
        flex:1;
        font-size: 1.5rem;
        font-weight: 700;
        
    }
    p{
        flex:1;
        font-size: 1.1rem;
        max-lines: 3;
    }
`;

const Card = styled.div`
  
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  /* aspect-ratio: 0.9; */
  /* aspect-ratio: 1.2; */

  @media ${media.tablet} {
    margin-top: 2%;
      width: calc(33.33% - 40px);
  }
`;
