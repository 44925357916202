import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';

const ProjectBannerSection = ({bgImg,logo,image,slogan,title,style,className,logoWidth}) => {
  return (
    <Section bgImg={bgImg} style={style} className={className || ''}>
    <Details>
      <div style={{margin:'5% 0'}}><img src={logo} alt='wns logo' style={{width:logoWidth || '20%'}}/></div>
      <Textwo>
        <TextHead>{title &&
            <texthead>{title || 'WNS Careers'}</texthead>
          }</TextHead>
        <Subtext>{slogan || 'Reshaping the CX of recruitment'}</Subtext>
      </Textwo>
    </Details>
    <ImgContainer className='image--container'>
      <img src={image}  alt='wns in laptop'/>
    </ImgContainer>
  </Section>
  )
}

export default ProjectBannerSection;

const Section = styled.div`
/* background-image: url('../../assets/svgs/wnsbackground.svg'); */
background:url(${props => props.bgImg});
background-size:cover;
justify-content: space-between;
display: flex;
/* position: relative; */
flex-direction:column;
min-height: unset;
overflow: hidden;

& img{
  width:100%;
  //height:50vh;
  
}
@media ${media.tablet} {
   flex-direction:row;
   height: 100vh;
   overflow: hidden;
   /* padding: 10% 0% 5% 6%; */
  }
`;

const Details = styled.div`
display: flex;
flex:1;
flex-direction:column;
gap:1rem;
padding: 15% 8%;
@media ${media.tablet} {
    gap:2.5rem;
  }
//position:absolute;
`;

const Textwo = styled.div`
font-family: 'Raleway', sans-serif;
font-weight:700;
font-size:1.3rem;
color: white;
display:grid;
row-gap: 12%;
`;

const TextHead = styled.div`
display: flex;
font-weight:600;
font-size:3rem;
color: white;
`;

const Subtext = styled.div`
font-weight:600;
font-size:1.5rem;
color: white;
`;
const ImgContainer = styled.div`
flex:2;
display: flex;
align-items: center;
/* overflow: hidden; */
justify-content:flex-end ;
& > img{
  width:100%;
  height: 100%;
  scale: 1.2;
  //height:50vh;
  
}
`;