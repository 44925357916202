import React from 'react'
import styled, { keyframes } from 'styled-components';

const AnimatedScrollingChevrons = () => {
    return (
        <Container>
            <Chevron />
            <Chevron />
            <Chevron />
        </Container>
    )
}

export default AnimatedScrollingChevrons;

const moveChevron = keyframes`
  25% {
    opacity: 1;
    transform: translateY(0.4rem);
  }
  33.3% {
    opacity: 1;
    transform: translateY(1.04rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(2.16rem);
  }
  100% {
    opacity: 0;
    transform: translateY(3.4rem) scale(0.5);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 4rem;
  transform: translateZ(0);
`;

const Chevron = styled.div`
  position: absolute;
  width: 1.78rem;
  height: 0.6rem;
  opacity: 0;
  transform: scale(0.3);
  animation: ${moveChevron} 1s ease-out infinite;

  &:nth-child(1) {
    animation-delay: 0.9s;
  }

  &:nth-child(2) {
    animation-delay: 1.2s;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background: #30B672;
  }

  &:before {
    background: #11baa6;
    left: 0;
    transform: skewY(30deg);
  }

  &:after {
    right: 0;
    transform: skewY(-30deg);
  }
`;