import TitleSection from 'components/TitleSection';
import { privacyPolicy, refundPolicy, termsAndConditions } from 'data/privacypolicy';
import React, { useEffect } from 'react'
import styled from 'styled-components';

const PrivacyPolicyPage = () => {
    const curr_path = window.location.pathname;
    useEffect(() => {
        goToId(curr_path)
    }, [curr_path]);

    const goToId = (hash_id = "privacy-policy") => {
        if (hash_id) {
          document
            .getElementById(hash_id.replace("/",""))
            ?.scrollIntoView({ block: "start" });
        }
      };
    
    return (
        <Section>
            <PrivacySection id="privacy-policya">
                <TitleSection title={'Privacy policy'}  textStyle={{fontSize:'1rem'}} />
                <br /><br /><br />
                {privacyPolicy.map((policy) => {
                    return (
                        <>
                            <p dangerouslySetInnerHTML={{__html:policy.desc}}/><br /><br />
                        </>
                    )
                })}
            </PrivacySection>
            <TermsSection id="terms-and-conditions">
                <TitleSection title={'TERMS OF SERVICE'}  textStyle={{fontSize:'1rem'}} />
                <br/><br/>
                <h1>Inspeero Technologies LLP Terms of Service</h1>
                <br /><br /><br />
                {termsAndConditions.map((term, index) => {
                    return (
                        <>
                            <h1>{index+1}. {term.heading}</h1>
                            <p dangerouslySetInnerHTML={{__html:term.desc}}/>
                            <br /><br/>
                        </>
                    )
                })}
            </TermsSection>
            <TermsSection>
                <TitleSection title={'REFUND POLICY'} textStyle={{fontSize:'1rem'}} />
                <br /><br /><br />
                {refundPolicy.map((policy) => {
                    return (
                        <>
                            <p dangerouslySetInnerHTML={{__html:policy.desc}}/><br /><br />
                        </>
                    )
                })}
            </TermsSection>
        </Section>
    )
}

export default PrivacyPolicyPage;

const Section = styled.div`
padding: 5% 8%;

p,a{
        font-family: 'Open Sans';
        font-size: 1.5rem;
        font-weight: 400;
        line-height:2rem;
    }
    a{
        text-decoration: none;
        color: black !important;
    }
`;

const PrivacySection = styled.section`

`;

const TermsSection = styled.section`
    margin: 5% 0;
    h1,b{
        font-family: "Open Sans";
        font-size: 1.5rem;
        margin: 1% 0;
    }
`;