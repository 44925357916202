import GradientHeadingSection from 'components/common-sections/GradientHeadingSection';
import React from 'react'
import styled from 'styled-components';

const WorkHeaderSection = () => {
  return (
    <Section>
        <GradientHeadingSection
        Title={'work'}
        GradientText={'glimpse of'}
        Primary={'Our work'}
        Secondary={'Inspeero drives customer growth and business goals through using technology andinnovation to create effective Web and App experiences for your customers and partners.'}
        />
    </Section>
  )
}

export default WorkHeaderSection;

const Section=styled.div`
padding: 5% 12% ;
`;