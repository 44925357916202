import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
// import coverpage from "assets/images/main_background_image.jpg";
import coverpage from "assets/images/coverphoto-homepage.png";
import media from "utils/media";
import { Link } from "react-router-dom";
import Button from "components/Button";
// import OurInsightsSection from "components/homepage-sections/OurInsightsSection";
import DividedSection from "components/homepage-sections/DividedSection";
import ShowoffCase from "components/homepage-sections/ShowoffCase";
import AnimatedScrollingChevrons from "components/AnimatedScrollingChevrons";
import PopupComponent from "components/popup-component/PopupComponent";
// import { WorkPortfolio } from "pages/workpage";
// import { workData } from "data/workdata";
// import WorkCard from "components/workpage-sections/WorkCard";

const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const words = [
    "websites.",
    "awesome stuff.",
    "web apps.",
    "mobile apps.",
    "growth strategies.",
    "digital products.",
  ];
  const [currentWord, setCurrentWord] = useState(words[wordIndex]);
  const [typing, setTyping] = useState(true);

  const OpenPopup = () => {
    setShowPopup(true)
  }

  useEffect(() => {
    // if (buttonClicked) {
    //   const novacButton = document.querySelector('div[data-novocall-id="AppWidgetButton3TqCNjuxm48"]');
    //   novacButton.click();
    //   setButtonClicked(false);
    // }
    if (showPopup) {
      [document.getElementById('main-route').style.overflow, document.getElementById('main-route').style.maxHeight] = ['hidden', '100vh'];
    } else {
      [document.getElementById('main-route').style.overflow, document.getElementById('main-route').style.maxHeight] = ['auto', 'none'];
    }
  }, [buttonClicked, showPopup]);

  useEffect(() => {
    let timeout;
    if (typing) {
      timeout = setTimeout(() => {
        setCurrentWord((prev) => {
          if (prev.length === words[wordIndex].length) {
            setTyping(false);
            return prev;
          }
          return words[wordIndex].slice(0, prev.length + 1);
        });
      }, 150);
    } else {
      timeout = setTimeout(() => {
        setCurrentWord((prev) => {
          if (prev.length === 0) {
            setTyping(true);
            setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
            return "";
          }
          return prev.slice(0, prev.length - 1);
        });
      }, 50);
    }
    return () => clearTimeout(timeout);
  }, [currentWord, typing, wordIndex, words]);
  return (
    <>
      <PopupComponent
        showPopup={showPopup}
        setShowPopup={setShowPopup}
      />
      <HomeWrapper>
        <TypewriterContainer>
          <SloganText>We turn ideas to&nbsp;</SloganText>
          <Typewriter>
            <span>{currentWord}</span>
          </Typewriter>
          <Button value={"Let's talk"} onClick={
            OpenPopup
            // () => setButtonClicked(true)
          }
          />
        </TypewriterContainer>
        <ScrollDownContainer data-aos="fade-up">
          <AnimatedScrollingChevrons />
          {/* <ScrollImage>
            <img src={ScrollDown}/>
          </ScrollImage> */}
          <a href="#who-is-inspeero">SCROLL DOWN</a>
        </ScrollDownContainer>
      </HomeWrapper>
      <IntroSection data-aos="fade-up" data-aos-delay="100" id="who-is-inspeero">
        <b>Who is Inspeero</b>
        <p>We are a team of innovators and achievers united by the passion to help businesses build world class digital experiences and products.</p>
        <p>
          <GradientLink onClick={
            OpenPopup
            // () => setButtonClicked(true)
          }>
            Contact Us
          </GradientLink>
          &nbsp; to take your inspiration and marry that with our innovation.
        </p>
      </IntroSection>
      <ShowoffCase/>
      {/* <WorkPortfolio>
                {
                    workData.filter((work)=>[3,4,5].includes(work.id)).map((work)=>{
                        return(
                        <WorkCard details={work}/>
                        )
                    })
                }
      </WorkPortfolio> */}
      {/* <ContentWrapper>
      </ContentWrapper> */}
      <DividedSection/>

      {/* <OurInsightsSection/> */}

    </>
  );
};

export default HomePage;

const GradientLink = styled(Link)`
font-size: 1.5rem;
font-weight: 700;
background: -webkit-linear-gradient(180deg, #11baa6, #30b66f);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

const IntroSection = styled.div`
  height: 50%;
  padding: 5% 8%;
  b{
    font-weight: 600;
    font-size: 2rem;
  }
  p{
    margin: 1rem 0;
    font-size: 1.4rem;
    line-height: 2.5rem;
  }

  @media ${media.tablet} {
    b{
      font-size: 2rem;
    }
    p{
      font-size: 1.5rem;
    }
  }

`;

const ScrollDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: lightgray;
  padding: 6% 0;

  a{
    text-decoration: none;
    color: lightgray;
    display: none;
    padding-bottom: 2%;
    font-size: small;
  }

  @media ${media.tablet} {
    font-size: medium;
    padding: 0;
    flex: 1;

    a{
      display: block;
    }
  }

`;
const ScrollImage = styled.div`
display: flex;
justify-content: center;

& img{
  width: 100%;
  height: 100%;
}
`;



const SloganText = styled.h1`
  padding-bottom: 2px;
  font-family: "Raleway";
  font-size: 4rem;
  font-weight: 700;
  color: #ffffff;
`;

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: end; */
  /* background-image: url(${coverpage}); */
  background-image: radial-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.5)), url(${coverpage});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  height: auto;
  padding: 0 2rem;

  @media ${media.tablet} {
    padding:0;
  }
`;



const TypewriterContainer = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top:50% ;
  gap: 1rem;

  @media ${media.tablet} {
    padding-left: 8%;
    padding-top:unset;
    justify-content: end;
    
  }
`;

const Typewriter = styled.div`
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
  /* white-space: nowrap; */

  &:after {
    content: "|";
    font-size: 4rem;
    display: inline-block;
    animation: blink 0.7s infinite;
    margin-left: 5px;
  }

  span {
    font-size: 4rem;
    background: -webkit-linear-gradient(180deg, #11baa6, #30b66f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;


