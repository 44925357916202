import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';
import twitterIcon from 'assets/images/twitter_icon.png';
import facebookIcon from 'assets/images/fb-icon-black.png';
import instagramIcon from 'assets/images/instagram_icon.png';
import linkedInIcon from 'assets/images/linkedin-black-icon.png';


const FollowUsSection = () => {
  return (
    <Section data-aos="flip-up">
      <h2>Follow us</h2>
      <SocialMediaIconsContainer>
        <SocialMediaIcon src={twitterIcon} alt="Twitter" />
        <SocialMediaIcon src={facebookIcon} alt="Facebook" />
        <SocialMediaIcon src={linkedInIcon} alt="LinkedIn" />
        <SocialMediaIcon src={instagramIcon} alt="Instagram" />
      </SocialMediaIconsContainer>
    </Section>
  )
}

export default FollowUsSection;

const Section = styled.div`
   padding: 0 8%;
   margin: 10% 0;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   gap: 1.5rem;
   
   h2{
    font-size: 2rem;
   }

   @media ${media.tablet} {
    padding: 0 12%;
    min-height:20vh;
    flex-direction: row;
    align-items: center;
    margin: 1% 0;
    h2{
        flex: 2;
    }
  }

`;


const SocialMediaIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex: 1;
  padding:0 2%;
  @media ${media.tablet} {
    /* width: 200px; */
  }
`;

const SocialMediaIcon = styled.img`
  height: 3.5rem;
  width: 3.5rem;
  padding: 2%;
  scale: 1.2;
  border: 2px solid black;

  @media ${media.tablet} {
    width: 3vw;
    height: 3vw;
    padding: 3%;
  }
`;
