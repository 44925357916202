// import simage1 from 'assets/images/service-portfolio-1.png';
import simage1 from 'assets/images/theaterheadshot_11_20221113142048.jpg';
import simage2 from 'assets/images/service-portfolio-2.png';
import simage3 from 'assets/images/service-portfolio-3.png';
import simage4 from 'assets/images/service-portfolio-4.png';


export const servicedata = [
    {
      id: 1,
      title: "STRATEGY",
      info: "We adapt a systems thinking approach to ensure that we identify short-term and long-term implications, prioritize relevant goals, and come up with actionable strategies for success.",
      image:simage1,
      subservice: [
        "Customer Journey Design",
        "Digital Experience",
        "Technology",
        "Market & Customer Analytics",
        "Nascent Technology Infusion",
        "Integrated Business & IT Roadmaps",
      ],
    },
    {
      id: 2,
      title: "DESIGN",
      info: "We use an agile,iteractive approach to create highly functioning intuitive designs driven by user insights.",
      image:simage2,
      subservice: [
        "Visual Design",
        "Prototyping & Testing",
        "Web Design",
        "Application Design",
        "UX Design",
      ],
    },
    {
      id: 3,
      title: "DEVELOPMENT",
      info: "We architect, build and develop exceptional digital products",
      image:simage3,
      subservice: [
        "DevOps",
        "App Development (Android & iOS)",
        "Fullstack Web Development",
        "Progressive Web App Development",
        "External Tools Integration",
        "Quality Assurance Testing",
      ],
    },
    {
      id: 4,
      title: "Digital marketing & growth",
      info: "We validate our decisions to learn, adapt and thrive.",
      image:simage4,
      subservice: [
        "Email Campaigns",
        "Technical SEO",
        "Digital Marketing",
        "Market Research & Audit",
        "Conversion Rate Optimization",
        "Social Media Marketing",
      ],
    },
];
  