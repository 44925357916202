import React, { useEffect, useLayoutEffect } from 'react'
import styled from 'styled-components';

const Button = ({ value, onClick, inversed, disabled, removeAnimation=false, style }) => {
  useLayoutEffect(() => {
    if(!removeAnimation){
      document.getElementById('cmn-btn').setAttribute('data-aos', 'fade-up')
    }else{
      document.getElementById('cmn-btn').removeAttribute('data-aos')
    }
    return () => {}
  }, [removeAnimation])
  
  return (
    <Btn id="cmn-btn" onClick={onClick} inversed={inversed} style={style} disabled={disabled}>{value}</Btn>
  )
}

export default Button;


const Btn = styled.button`
  background-color: transparent;
  color: ${({ inversed }) => (inversed ? "black" : "#fff")};
  padding: 16px 32px;
  margin-top: 1rem;
  font-size: 1.15rem;
  font-weight: bold;
  border: ${({ inversed }) => (inversed ? "3px solid black" : "3px solid #fff")};
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor:${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    background-color: ${({ inversed }) => (inversed ? "black" : "#fff")};
    color: ${({ inversed }) => (inversed ? "#fff" : "black")};
  }
`;