import seattle from 'assets/images/seattle.png';
import sanfransisco from 'assets/images/SanFrancisco.png';
import losangeles from 'assets/images/Los_20angeles.png';
// import miami from 'assets/images/Miami.png';
import mumbaiImage from 'assets/images/mumbai.png';
export const officeLocations=[
    {
        name:'Seattle',
        image:seattle
    },
    {
        name:'San Fransisco',
        image:sanfransisco
    },
    {
        name:'Los Angeles',
        image:losangeles
    },
    // {
    //     name:'Miami',
    //     image:miami
    // },
    {
        name:'Mumbai',
        image:mumbaiImage
    },
]