import React from 'react';
import styled from 'styled-components';
import Wnsorange from 'assets/svgs/wnsbackground.svg';
import wnslaptop from '../../assets/svgs/Laptop.svg';
import media from 'utils/media';
import mobile from 'assets/svgs/mobile.svg';
import newwebsite from 'assets/images/newwebsite.png';
import wnslaptoptwo from 'assets/images/wnslaptoptwo.svg';
import wnsLogo from 'assets/images/wns-logo.png';
import ContributionIntro, { DetailsSection } from 'pages/antworks/ContributionIntro';
import ProjectBannerSection from 'components/common-sections/ProjectBannerSection';

const WnsPage = () => {
  return (
    <PageContainer>
      <ProjectBannerSection
       title={'WNS Careers'}
       bgImg={Wnsorange}
       image={wnslaptop}
       logo={wnsLogo}
       slogan={'Reshaping the CX of recruitment'}
      />
      <Section>
        <ContributionIntro
          heading={'WNS'}
          paragraph={'WNS is a global provider of Business Process Management and Outsourcing solutions for 200+ companies worldwide across a wide spectrum of industries.'}
          contributionArray={['Development', 'UI Design', 'UX Design', 'Strategy + Innovation']}
        />
        <Mobileimg>
          <img src={mobile} alt='group of mobiles' data-aos="fade-zoom-in" />
        </Mobileimg>
        <ContainerWithPadding>
          <DetailsSection heading={'Mobile first'} paragraph={'Almost 5 billion people are approximately mobile users in 2019 and it is only growing up. Keeping that in mind, we were tasked to create the career portal keeping mobile responsiveness in mind. Fluid navigation, better visual transitions and legibility throughtout the portal for a smoother experience on mobile were the primary objectives for us.'} />
        </ContainerWithPadding>
        <Slidder>
          <Sliddertext data-aos="fade-zoom-in">Glide the slider left or right to see the transformation of the website.</Sliddertext>
          <Slidderimg>
            <img src={newwebsite} alt='old to new conversion' data-aos="fade-zoom-in"/>
          </Slidderimg>
        </Slidder>
        <ContainerWithPadding>
          <DetailsSection style={{ textAlign: 'center' }} heading={'Carrying the website into the modern era'} paragraph={'It was not an easy task to carry the career portal used by one of the top MNC’s in India into the modern design and UX practices. With thorough research, branding studies and ideas we were able to thoughtfully bring the website upto modern design standards and user experiences.'} />
        </ContainerWithPadding>
        <div className='big-img--container'>
          <img src={wnslaptoptwo} alt='group-of-mobiles' data-aos="fade-zoom-in" />
          <DetailsSection style={{ textAlign: 'center' }} heading={'Reusable design, maximum impact'} paragraph={'Reusable blocks and components were designed to increase the efficiency of the portal development. The portal was launched in 2019 and still runs robustly.'} />
        </div>
      </Section>

      {/* <CaseStudiesSection/> */}
    </PageContainer>
  )
}

export default WnsPage;

const ContainerWithPadding = styled.div`
  padding:0 5%;
  margin: 10% 0;
  @media ${media.tablet} {

  }
`;

const PageContainer = styled.section`
   overflow-x: hidden;
  .main-hero--section{
    min-height: unset;
    padding-top:25%;
  }
  @media ${media.tablet} {
    .main-hero--section{
      padding-top:0;
      min-height: 100vh;
    }
  }

`;
const Section = styled.div`
  padding:5% 0;
  min-height: 100vh;

  .big-img--container{
    
    display: flex;
    flex-direction: column;
    /* min-height: 60vh; */
    gap: 1.5rem;
    > div{
      padding:0 5%;
    }
    @media ${media.laptopL} {
      flex-direction: column;
    }
  }

`;

const Mobileimg = styled.div`
display: flex;
justify-content: center;
& img{
  width:70%;
  height: 70%;;
  
}
`;

const Slidder = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`;

const Sliddertext = styled.div`
font-family: 'Raleway', sans-serif;
text-align: center;
font-weight:700;
font-size:1.5rem;
line-height:2.25rem;
letter-spacing:-1.5%;
padding: 0% 15%;
`;

const Slidderimg = styled.div`
display: flex;
justify-content: center;
padding: 2% 0%;
& img{
  width:90%;
  height: 90%;;

}
`;
