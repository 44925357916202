import Button from 'components/Button';
import GradientHeadingSection from 'components/common-sections/GradientHeadingSection';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NotFoundPage = () => {
    const navigate=useNavigate();
    return (
        <Section>
            <GradientHeadingSection
                Title={'404 - PAGE NOT FOUND!'}
                GradientText={'Seems like you are lost!'}
                Secondary={'Don’t worry we will get you out of here.'}
            />
            <br/>
            <Button value={'Inspeero Home Page'} onClick={()=>navigate('/')} inversed={true}/>
        </Section>
    )
}

export default NotFoundPage;


const Section = styled.section`
    min-height: 70vh;
    padding:8% 12%;
`;