import React from 'react'
import styled from 'styled-components';
import ServiceComponent from './ServiceComponent';
import media from 'utils/media';
import { servicedata } from 'data/servicedata';

const ServicesPortfolio = () => {
  return (
    <Section>
        {
          servicedata.map((sd)=>{
            return(
              <ServiceComponent description={sd}/>
            );
          })
        }
        {/* <ServiceComponent image={simage1} description={servicedata}/>
        <ServiceComponent image={simage2} description={servicedata}/>
        <ServiceComponent image={simage3} description={servicedata}/>
        <ServiceComponent image={simage4} description={servicedata}/> */}
    </Section>
  )
}

export default ServicesPortfolio;

const Section=styled.section`
    padding: 0 12%;
    padding-bottom: 8%;
    display: flex;
    flex-direction: column;
    gap:3rem;
    @media ${media.tablet} {
        gap: 5rem;
  }
`;