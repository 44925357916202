import React from 'react'
import styled from 'styled-components';
import ShowoffBlock from './ShowoffBlock';
import antWorkbg from 'assets/images/antwork-bg.svg';
import antWorkLogo from 'assets/images/antwork-logo.png';
import antWorkImage from 'assets/images/antwork-image.png';
import modulusBg from 'assets/images/modulus-bg.svg';
import modulusCover from 'assets/images/modulus-cover.svg';
import modulusLogo from 'assets/images/modulus-logo.svg';
import media from 'utils/media';


const ShowoffCase = () => {
  return (
    <Section>
        <ShowoffBlock link='wns'
         imageStyles={{transform:`${window?.innerWidth>768 ? "scale(1.2) translate(5%,10%)" : ""}`}}
         />
        <ShowoffBlock
        bgImg={antWorkbg}
        logo={antWorkLogo}
        image={antWorkImage}
        link={'/antworks'}
        imageStyles={{transform:`${window?.innerWidth>768 ? "scale(1.1) translate(5%,-5%)" : ""}`}}
        />
        <ShowoffBlock
         bgImg={modulusBg}
         logo={modulusLogo}
         image={modulusCover}
        //  imageStyles={{transform:'translateY(18%)'}}
         imageStyles={{transform:`${window?.innerWidth>768 ? "scale(1.2) translateY(5%)" : ""}`}}
        link='modulus'/>
    </Section>
  )
}

export default ShowoffCase;

const Section=styled.section`
/* display: flex; */
display:flex;
flex-wrap: wrap;
flex-direction: column;
@media ${media.tablet} {
        flex-direction: row;
    }
`;



