import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'utils/media';
import MeetScreen from './MeetScreen';
import crossIcon from 'assets/svgs/black-cross-con.svg';
import { scheduleData } from 'data/scheduledata';
import CustomLoader from 'components/CustomLoader';

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  pointer-events: ${({ visible }) => visible ? 'unset' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  ${({ visible }) => visible && 'opacity: 1;'};
`;

const Popup = styled.div`
  background-color: white;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  transform:scale(0);
  transition: transform 0.3s ease-in;
  ${({ visible }) => visible && 'transform:scale(1);'};
  iframe{
    height: 100%;
    width: 100%;
    border:none;
  }
  @media ${media.tablet} {
      width: 70vw;
      min-height: 75vh;
      max-height: 90%;
  }
  @media ${media.desktopL} {
    max-height: 80%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 2%;
  right:2%;
  font-weight: 800;
  scale: 1.1;
  border: none;
  background-color:white;
  &:hover{
    cursor: pointer;
  }

  @media ${media.tablet} {
    scale: 1.2;
    right: 2%;
    top:3%;
  }
  
`;

function PopupComponent({ showPopup, setShowPopup }) {
  const [selectedSchedule, setSelectedSchedule] = useState("Fullstack Product Consultation");
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
    document.body.classList.add('no-scroll');
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedSchedule("");
    setSubmitted(false);
    document.body.classList.remove('no-scroll');
  };

  const appendSrcInHead = (src, callback) => {
    setIsLoading(true);
    document.querySelector("script[title='novoscript']")?.remove();
    document.querySelector("div.novocall")?.remove();
    const head = document.getElementsByTagName("head")[0];
    const scriptElement = document.createElement("script");
    scriptElement.title = "novoscript";
    scriptElement.async = !0;
    scriptElement.type = "text/javascript";
    scriptElement.src = src;
    scriptElement.onload = callback;
    head.appendChild(scriptElement);
    return true;
  }

  const handlenovaPopup = () => {
    if (selectedSchedule !== '') {
      let sd = scheduleData.find(a => a.title === selectedSchedule);
      let addInHead = appendSrcInHead(sd.src, () => {
        let queryToFind = `div[data-novocall-id=${sd.appId}]`;
        let novoButton = document.querySelector(queryToFind);
        novoButton?.click();
        closePopup();
        setIsLoading(false);
      });
    }
  }

  useEffect(() => {
    submitted && handlenovaPopup();
  }, [submitted])

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <PopupContainer visible={showPopup}>
      <Popup visible={showPopup}>
        <CloseButton onClick={closePopup}>
          <img src={crossIcon} alt='loading..' />
        </CloseButton>
        {isLoading ? (
          <CustomLoader/>
        ) :
          (
            <MeetScreen
              selectedSchedule={selectedSchedule}
              setSelectedSchedule={setSelectedSchedule}
              setSubmitted={setSubmitted} />
          )}
      </Popup>
    </PopupContainer>
  );
}

export default PopupComponent;
