import React from 'react'
import styled from 'styled-components';
import gmailLogo from 'assets/images/logos_google-gmail.svg';
import media from 'utils/media';

const SideInfo = ({
    logo,
    heading,
    info,
    link,
    linkType
}) => {
    return (
        <Section>
            <div className='contact--icon'>
                <IconContainer>
                    <img src={logo || gmailLogo} />
                </IconContainer>
            </div>
            <div className='contact--details'>
                <h1>{heading || 'Mail us'}</h1>
                <h3>{info || 'Our friendly team'}</h3>
                <a href={`${linkType}:${link}`}>
                    {link}
                </a>
            </div>
        </Section>
    )
}

export default SideInfo;

const Section = styled.div`
margin: 2% 0;
padding: 2% 1%;
display: flex;
font-family: 'Open Sans';

@media ${media.tablet} {
    margin: 4% 0;
    padding: 2% 5%;
    /* min-height: 15vh; */

}

.contact--icon{
    flex: 1 1 20%;
    padding: 2%;
    display: flex;
    justify-content: center;
}

.contact--details{
    flex: 1 1 80%;
    padding: 2%;
    display: flex;
    gap: 0.4rem;
    flex-direction: column;
    h1{
        padding:0 2%;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 1.2em;
    }
    h3{
        color: var(--darkgray);
        padding:0 2%;
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 1em;
    }
    a{
        text-decoration: none;
        font-family: 'Open Sans';
        width: fit-content;
        padding: 4% 6%;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 23px;
        font-weight: 600;
        font-size: 90%;
        color: #3A435F;
    }
}
`;

const IconContainer = styled.div`
border: 1px solid rgba(0, 0, 0, 0.3);
border-radius: 11.25px;
height: 2em;
background-color: white;
aspect-ratio: 1.2;
display: grid;
place-items: center;
img{
    width: 60%;
    height: 60%;
}
`;