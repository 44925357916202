import React from 'react'
import styled from 'styled-components';
import simage1 from 'assets/images/service-portfolio-1.png';

import DescriptionComponent from './DescriptionComponent';
import media from 'utils/media';

const ServiceComponent = ({image,description}) => {
  return (
    <Container>
      <ImageContainer data-aos="flip-up">
        <Image src={description?.image || image} alt="Full-width image" />
      </ImageContainer>
      <DescriptionComponent desc={description}/>
    </Container>
  )
}

export default ServiceComponent;

const Container = styled.div`
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-height: 60vh;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: unset;  
  @media ${media.tablet} {
      /* aspect-ratio: 0.9;   */
  }
`;
