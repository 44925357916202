import SelfApp from 'assets/images/SELFAPP.png';
import Greencat from 'assets/images/GREENCAT.png';
import WNS from 'assets/images/WNS.png';
import AntWorks from 'assets/images/ANTWORKS.png';
import Modulus from 'assets/images/MODULUS.png';
import GajIQ from 'assets/images/GAJIQ.png';
import Swivel from 'assets/images/Swivel.png';
import Blacksoil from 'assets/images/Blacksoil.png';
import CommandOP from 'assets/images/CommandOP.png';
import Mobitrash from 'assets/images/mobitrash-screenshot.png';
import Chances from 'assets/images/Chances.png';
import iTAP from 'assets/images/itap-screenshot.png';

export const workData = [
    {
        id: 7,
        title: "Swivel",
        info: "The dating app designed to bridge the gap between online dating and meeting organically.",
        image: Swivel,
        subservice: [],
    },
    {
        id: 8,
        title: "Blacksoil Capital",
        info: "Digitalizing business processes and developing systems for an alternative credit firm.",
        image: Blacksoil,
        subservice: [],
    },
    {
        id: 9,
        title: "CommandOP",
        info: "End-to-end system development & delivery for a digital signage company.",
        image: CommandOP,
        subservice: [],
    },
    {
        id: 10,
        title: "Chances",
        info: "Building a dating app that has a new take on how social relationships can be fun.",
        image: Chances,
        subservice: [],
    },
    {
        id: 3,
        title: "WNS",
        info: "Reshaping a multinational company customer experience for international recruiting.",
        image: WNS,
        subservice: [],
    },
    {
        id: 4,
        title: "AntWorks",
        info: "Engage employees and inform managers using AI to build periodic smart surveys for the whole company or specific departments.",
        image: AntWorks,
        subservice: [],
    },
    {
        id: 5,
        title: "Modulus",
        info: "State of the art Web3 blockchain and token platform for creating trust through immutable transactions.",
        image: Modulus,
        subservice: [],
    },
    {
        id: 1,
        title: "SELF App",
        info: "A 3-dimensional health and wellness app that rewards positive accomplishments.",
        image: SelfApp,
        subservice: [],
    },
    {
        id: 2,
        title: "GreenCat",
        info: "Manage industry exhibition experiences to improve attendee experience and drive follow-on contact",
        image: Greencat,
        subservice: [],
    },
    {
        id: 6,
        title: "GajIQ",
        info: "A friendly and free app for managing employee attendance, work, payment & salary across all your businesses. All-in-one access!.",
        image: GajIQ,
        subservice: [],
    },
    {
        id: 11,
        title: "iTAP",
        info: "New age OTT platform built to provide entertainment, engagement and rewards." ,
        image: iTAP,
        subservice: [],
    },
    {
        id: 12,
        title: "Mobitrash",
        info: "Connecting sustainable processes through digitization." ,
        image: Mobitrash,
        subservice: [],
    },
];