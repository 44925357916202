import TitleSection from 'components/TitleSection';
import React from 'react'
import styled from 'styled-components';

const GradientHeadingSection = ({ Title, GradientText, Primary, Secondary }) => {
  return (
    <Block>
      <TitleSection title={Title || 'Work'} />
      <BlockQuoteSection>
        <GradieentH1>{GradientText || 'glimpse of'}</GradieentH1>
        <h2>{Primary}</h2>
      </BlockQuoteSection>
      {Secondary &&
        (
          <p>{Secondary || ''}</p>
        )}
    </Block>
  )
}

export default GradientHeadingSection;

const Block = styled.div`
    display: grid;
    row-gap: 2rem;
    h2{
      font-size: 3.75rem;
      font-weight: 600;
    }
    p{
      font-size: 1.5rem;
      font-weight: 500;   
      color: #000000;
    }
`;

const BlockQuoteSection = styled.div`
  display: grid;
`;
const GradieentH1 = styled.h1`
    font-size: 3.75rem;
    font-weight: 600;
    background: -webkit-linear-gradient(180deg, #11baa6, #30b66f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;