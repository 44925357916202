import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => {
    return (
        // <Section>
        //     <Branches>
        //     Seattle | San Francisco | Los Angeles | Miami | Mumbai
        //     </Branches>
        //     <br></br>
        //     <BottomLinks>
        //         <Link>Privacy Policy</Link>
        //         <Link>Terms of service</Link>
        //     </BottomLinks>
        // </Section>
        <Parentfooter>
         <Onefooter>
            <Sudiv>Seattle</Sudiv>
            <Sudiv>|</Sudiv>
            <Sudiv>San Francisco</Sudiv>
            <Sudiv>|</Sudiv>
            <Sudiv>Los Angeles</Sudiv>
            <Sudiv>|</Sudiv>
            <Sudiv>Miami</Sudiv>
            <Sudiv>|</Sudiv>
            <Sudiv>Mumbai</Sudiv>
         </Onefooter>
         <Twofooter>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-conditions">Terms of services</Link>
         </Twofooter>
        </Parentfooter>
    )
}

export default Footer;

const Parentfooter = styled.div`
    height: 20%;
    padding: 2.8rem 0;
    background-color: #171717;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
   
    
`;


const Onefooter = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-content: center;

   // flex-direction: row;
   //justify-content: space-between;
    color: white;
    //gap:0.5rem;
      
    font-size: 1.2rem;
    font-weight:700;
    //width:40%;
    
`;
const Sudiv= styled.div`
display:flex;
justify-content: center;
align-item: center;
    color:white;
margin-left:10px;
    
`;

const Twofooter = styled.div`
 display: flex;
 gap: 4rem;
 color: white;
 font-size: 1.2rem;
 font-weight:700;
 text-decoration: none;
 a{
    color: white;
    text-decoration: none;
 }
`;