import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';
import { DetailsSection } from './ContributionIntro';
import mobImg from 'assets/images/antwork-mobile-screenshot.svg';

const ImagePlusDesc = ({ Img, heading, description, reversed, style, imagestyle, fullSize, Scale, className }) => {
  return (
    <Section style={style} reversed={reversed} fullSize={fullSize} Scale={Scale} className={className}>
      <div className="img-container" data-aos={`${reversed ? "fade-left" : "fade-right"}`}>
        <img src={Img || mobImg} alt='project-screenshot' style={imagestyle} />
      </div>
      <div className='details-section--container'>
        <DetailsSection fadeDdirection={`${reversed ? "right" : "left"}`} heading={heading || 'AntPulse'} paragraph={description || 'AntPulse is an automated employee engagement and performance management product envisoned by AntWorks for internal purposes to track behaviours and attitudes of an employee in relation to its key metrics.The app is available to download on Android & iOS.'} />
      </div>
    </Section>
  )
}

export default ImagePlusDesc;

const Section = styled.div`
    padding:0 5%;
    display: flex;
    margin-top:8%;
    flex-direction: column;
    align-items: center;

    .details-section--container{
      display: flex;
      align-items: center;
      justify-content: ${({ reversed }) => reversed ? 'flex-start' : "flex-end"};
      & > div{
        overflow: hidden;
        overflow-wrap: break-word;
      }

    }
    .img-container{
      flex:1;
      display: flex;
      justify-content: center;
      padding:${props => props.fullSize ? '' : '12%'};
      
      img{
        width:100%; 
      }
    }
    @media ${media.laptop} {
       
        
        margin-top: 0;
        flex-direction: ${({ reversed }) => reversed ? 'row-reverse' : "row"};
        min-height: 100vh;
        
        .img-container{
            padding: 0;
            margin-top:${props => props.fullSize ? '0' : '-5%'};
            margin-left:${props => props.fullSize ? '0' : '8%'} ;
            justify-content: flex-start;
          
          img{
            width: unset;
            scale:${props => props.Scale ? props.Scale : " "} ;
          }

        }
        .details-section--container{
          & > div {
            width: 60%;
          }
        }
    }
`;