import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Whitelogo from "assets/svgs/il.svg";
import Blacklogo from "assets/svgs/il-black.svg";
import hamburgerIcon from 'assets/svgs/hamburger-menu-icon.svg'
import styled, { css } from 'styled-components';
import media from 'utils/media';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHomepage, setIsHomePage] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const location = useLocation();
  const homePageRoutes=['/','/wns','/antworks','/modulus'];

  useEffect(() => {
    setIsHomePage(true);
    // if (['/work', '/services', '/contact','/privacy-policy','/terms-and-conditions'].includes(location.pathname)) {
    if (homePageRoutes.includes(location.pathname)) {
      setIsHomePage(true)
    } else {
      setIsHomePage(false)
    }
    if (isOpen) {
      [document.getElementById('main-route').style.overflow, document.getElementById('main-route').style.maxHeight] = ['hidden', '100vh'];
    } else {
      [document.getElementById('main-route').style.overflow, document.getElementById('main-route').style.maxHeight] = ['auto', 'none'];
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location, isOpen]);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  return (
    <HeaderWrapper scrolled={isScrolled} homePage={isHomepage}>
      <LogoWrapper>
        <Link to="/">
          <LogoImage src={isHomepage ? Whitelogo : Blacklogo} alt="Logo" />
        </Link>
      </LogoWrapper>
      <MenuWrapper>
        <MenuList>
          <MenuItem>
            <MenuLink to="/work" homePage={isHomepage}>Work</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/services" homePage={isHomepage}>Services</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/contact" homePage={isHomepage}>Contact</MenuLink>
          </MenuItem>
        </MenuList>
        {isOpen ?
          (
            <>
              {/* <Cross onClick={toggleSidebar}>
                <svg viewBox="0 0 100 80" width="40" height="40">
                  <rect x="25" y="35" width="50" height="10"></rect>
                  <rect x="35" y="25" width="10" height="50"></rect>
                </svg>
              </Cross> */}
            </>
          ) :
          (
            <>
              <Hamburger onClick={toggleSidebar} homePage={isHomepage}>
                <img src={hamburgerIcon} />
              </Hamburger>
            </>
          )}
      </MenuWrapper>
      <Sidebar className={isOpen ? 'open' : ''}>
        <SidebarMenu>
          <MenuLink to="/work" homePage={isHomepage} onClick={toggleSidebar}>Work</MenuLink>
          <MenuLink to="/services" homePage={isHomepage} onClick={toggleSidebar}>Services</MenuLink>
          <MenuLink to="/contact" homePage={isHomepage} onClick={toggleSidebar}>Contact</MenuLink>
        </SidebarMenu>
        <CrossContainer onClick={toggleSidebar}>
          <span>
            X
          </span>
        </CrossContainer>

      </Sidebar>
    </HeaderWrapper>
  )
}

export default Header;

const CrossContainer = styled.div`
  padding:5%;
  display: flex;
  justify-content: flex-end;
  color: var(--white);
  span{
    font-size: xx-large;
    font-weight: 700;
  }
`;

const HeaderWrapper = styled.header`
  ${({ homePage }) =>
    homePage &&
    css`
   position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: transparent;
  `
    ||
    css`
  background-color: white;
  `
  }
 
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, padding 0.1s ease-in-out;
  padding: 2rem 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ scrolled }) =>
    scrolled &&
    css`
      padding: 1rem 5% !important;
      background-color: black;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    `}
  
    @media ${media.tablet} {
      padding: 2rem 5%;
    }
`;

const LogoWrapper = styled.div`
  display: inline-block;
`;

const LogoImage = styled.img`
  height: 50%;
  width: 50%;
`;

const MenuWrapper = styled.nav`
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;

  @media ${media.tablet} {
    width: 30%;
  }

`;

const MenuList = styled.ul`
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
  justify-content: space-around;
  align-items: center;
  @media ${media.tablet} {
    display:flex;
  }
`;

const MenuItem = styled.li`
  display: inline-block;
  margin-left: 20px;
`;

const MenuLink = styled(Link)`
  --link-color:white;
  text-decoration: none;
  color: var(--link-color);
  position: relative;
  ${({ homePage }) =>
    !homePage &&
    css`
   --link-color:black;
  `
  }

	&:before {
		content: "";
		position: absolute;
		width: 140%;
		height: 2px;
		bottom: 0;
		margin: -6px 0;
		background-color: var(--link-color);
		visibility: hidden;
		transform: translateX(-10%) scaleX(0);
		transition: all 0.1s ease-in-out 0s;
	}
	&:hover:before {
		visibility: visible;
		transform: translateX(-10%) scaleX(1);
	}

  &:hover {
    font-weight: 600;
  }
`;

const Sidebar = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, rgba(14, 188, 210, 0.98) 0%, rgba(39, 178, 131, 0.98) 100%);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  display: flex;

  @media ${media.tablet} {
    display:none;
  }

  &.open {
    transform: translateX(0);
  }
`;

const SidebarMenu = styled.ul`
  display: flex;
  flex:1;
  flex-direction: column;
  gap: 2rem;
  align-items: baseline;
  justify-content: center;
  list-style: none;
  padding: 10% 0 10% 10%;

  a{
    font-size: 2.5rem;
    color:var(--white);
    font-weight: 700;
  }
`;

const Hamburger = styled.div`
  
  display: block;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  /* overflow: hidden; */
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    ${({ homePage }) =>
    !homePage &&
    css`
    filter: invert(1);
  `
  }
  }
  
  @media ${media.tablet} {
    display:none;
  }
`;
