import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';

const TitleSection = ({ title,style,textStyle }) => {
    return (
        <Section style={style}>
            <h3 data-aos="fade-right" style={textStyle}>{title}</h3>
        </Section>
    )
}

export default TitleSection;

const Section = styled.div`
text-transform:uppercase;
margin-left: 1%;
letter-spacing: 5px;
h3{
    font-weight: 700;
    font-size: medium;
}

@media ${media.tablet} {
    margin-left: 2px;
  }
`;