import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';

const ContributionIntro = ({heading,paragraph,contributionArray=['Mobile App Development','Backend Development','Automated testing','Strategy + Inovation']}) => {
    return (
        <Container>
            <DetailsSection fadeDdirection='right' heading={heading} paragraph={paragraph}/>
            <ContributionSection data-aos="fade-left" data-aos-delay="500">
                <h2>Our Contribution</h2>
                <div>
                    {
                        contributionArray?.map((contri)=>{
                            return(
                                <p>{contri}</p>
                            )
                        })
                    }
                </div>
            </ContributionSection>
        </Container>
    )
}

export default ContributionIntro;

const Container = styled.div`
padding:0 5%;
display: flex;
flex-direction: column;
gap: 3.5rem;
    > div:first-child{
        flex: 1;
    }

    h2{
        font-size: 2rem;
        font-weight: 600;
    }
    p{
        font-size: 1.3rem;
        line-height: 4rem;
        font-weight: 400;
    }
    @media ${media.laptop} {
    min-height: 45vh;
    display:flex;
    flex-direction: row;
    p{
        line-height: 3rem;
    }
    .details-paragraph{
        width: 65%;
    }
  }
`
const DSection = styled.div`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        h2{
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 2.25rem;
            }
        p{
            font-size: 1.4rem;
            line-height: 2.5rem;
            font-weight: 400;
            width:100%;
            }
        @media ${media.laptopL} {
            }

`;

const ContributionSection = styled.div`
display: flex;
gap: 2.5rem;
flex-direction: column;
align-items: flex-start;
    > div{
        display: grid;
        /* row-gap: 1.5rem; */
    }

    @media ${media.laptop} {
        gap:2rem;
        align-items: flex-end;
        div{
            text-align: end;
        }
    }
`;

export const DetailsSection = ({ heading, paragraph, style, fadeDdirection="up" }) => {
    return (
        <DSection style={style} data-aos={`fade-${fadeDdirection}`}>
            <h2>
                {heading || 'AntWorks'}
            </h2>
            <p className='details-paragraph'>
                {paragraph || 'AntWorks™ is a global, artificial intelligence (AI) and intelligent automation (IA) company that creates new possibilities with data through digitisation, automation, and enterprise intelligence.'}
            </p>
        </DSection>
    )
}