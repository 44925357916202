import GradientHeadingSection from 'components/common-sections/GradientHeadingSection';
import ServicesPortfolio from 'components/services-sections/ServicesPortfolio';
import React from 'react'
import styled from 'styled-components';

const ServicesPage = () => {
  return (
    <Section>
      <div className='heading-section--container'>
        <GradientHeadingSection
          Title={'Services'}
          GradientText={'building things'}
          Primary={'that matter.'}
          Secondary={'At Inspeero, we take your vision and run with it. Our team of agile experts applies a personalized approach to each task, offering you innovative digital solutions that help your brand stand out from the crowd.'}
        />
      </div>
      <ServicesPortfolio/>
    </Section>
  )
}

export default ServicesPage;

const Section = styled.div`
  .heading-section--container{
    padding: 5% 12%;
  }
`;
