import React from 'react';
import styled from 'styled-components';
import media from 'utils/media';
import wnsBg from 'assets/svgs/wnsbackground.svg';
import wnsLogo from 'assets/images/wns-logo.png';
import wnsImg from 'assets/images/wns-image.png';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';

const ShowoffBlock = ({bgImg,logo,image,slogan,link,style,className,imageStyles}) => {
  return (
    <Section bgImg={bgImg} style={style} className={className || ''}>
    <DetailsSection>
        <DetailsBlock logo={logo} slogan={slogan} link={link}/>
    </DetailsSection>
    <ImageSection>
        <img src={image || wnsImg} alt='showcase-img' data-aos="fade-left" style={imageStyles}/>
    </ImageSection>
    </Section>
  )
}

export default ShowoffBlock;

const Section=styled.div`
    flex:1 1 30.4%;
    min-height:70vh;
    background:url(${props => props.bgImg || wnsBg});
    background-size: cover;
    display:flex;
    flex-direction: column;
    @media ${media.tablet} {
        flex-direction: column;
        height: 60vh;
    }
`;

const DetailsSection=styled.div`
    flex: 1;
    display: flex;
    padding:0 8%;
    align-items: center;
    justify-content: space-between;
`;

const ImageSection=styled.div`
    flex: 1;
    overflow: hidden;
    position: relative;
    display: flex;
    padding:0 8%;
    align-items: center;
    justify-content: space-between;
    img{
        width: 100%;
        scale:1.5;
        @media ${media.tablet} {
            scale:1;
        }

        @media ${media.desktop} {
            scale: 1.5;
    }
    }
`;

const DBlock=styled.div`
 padding: 1rem;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 gap: 1rem;
 color: var(--white);
 p{
    font-size: 1.5rem;
    font-weight: 400;
 }
`;

export const DetailsBlock=({logo,slogan,link='/wns'})=>{
    const navigate=useNavigate();
    return(
        <DBlock data-aos="fade-up">
            <img src={logo || wnsLogo} alt="logo"/>
            <p>{slogan || 'Reshaping the CX of recruitment'}</p>
            <Button value={'Read Case Study'} onClick={()=>navigate(link)}/>
        </DBlock>
    )
}

