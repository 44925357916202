import React from 'react';
import styled from 'styled-components';
import mumbaiImage from 'assets/images/mumbai.png';
import media from 'utils/media';

const CardWithImg = ({image,name}) => {
  return (
    <CardSection data-aos="fade-up">
    <CardImage src={image || mumbaiImage} alt="location" />
    <CardCaption>{name}</CardCaption>
  </CardSection>
  )
}

export default CardWithImg;

const CardSection=styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: unset;  
  @media ${media.tablet} {
    gap: 2rem;
    width: calc(50% - 10px);
    aspect-ratio: 1.2; /* set aspect ratio to 1.2:1 (width:height) */
  }

`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain; /* fit the image within the container */

  @media ${media.tablet} {
    width: 80%;
  }
`;

const CardCaption = styled.div`
  width: 100%;
  text-align: left;
  color:var(--black);
  font-size: 2rem;
  font-weight: 700;

  @media ${media.tablet} {
    width: 80%;
  }
`;