import React from 'react'
import styled from 'styled-components';
import CardWithImg from './img-card';
import media from 'utils/media';
import { officeLocations } from 'data/officelocations';


const OurOfficesSection = () => {
  return (
    <Section>
      <h2>Our Offices</h2>
      <OfficesPortfolio>
        {officeLocations?.map((location)=>{
          return(
            <CardWithImg image={location.image} name={location.name} />
          )
        })}
      </OfficesPortfolio>
    </Section>
  )
}

export default OurOfficesSection;

const Section = styled.div`
padding:2rem 8%;
h2{
    margin: 5% 0;
    font-size: 2.25rem;
    font-weight: 700;
}
@media ${media.tablet} {
  h2{
    margin: 5% 5%;
  }
}
`;

const OfficesPortfolio = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem; /* remove gap on smaller screens */


  @media ${media.tablet} {
    flex-direction: row;
    gap: 1rem; /* add gap between cards */
  }
`;