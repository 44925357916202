import TitleSection from 'components/TitleSection';
import React from 'react'
import styled from 'styled-components';
import media from 'utils/media';

const DescriptionComponent = ({ desc }) => {
    return (
        <TextContainer>
            <TitleSection title={`- ${desc?.title}` || '- growth'}/>
            <Paragraph data-aos="fade-up">
                { desc?.info || 'We adapt a systems thinking approach to ensure that we identify short-term and long-term implications, prioritize relevant goals, and come up with actionable strategies for success.'}            </Paragraph>
            <List data-aos="fade-up">
              {
                desc?.subservice?.map((service)=>{
                  return(
                    <ListItem>{service}</ListItem>
                  )
                })
              }
            </List>
        </TextContainer>
    )
}

export default DescriptionComponent;


const TextContainer = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: grid;
  row-gap: 1rem;

  h3{
      font-size: 1.5rem;
      letter-spacing: 10px;
      background: linear-gradient(180deg, #12BAC6 0%, #38B450 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
  }

  @media ${media.laptop} {
  }
`;

const Paragraph = styled.p`
  padding-left: 2.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
`;

const List = styled.ul`
  padding-left: 2.5rem; 
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap:1rem;
  @media ${media.tablet} {
    flex-direction: row;
  }
`;

const ListItem = styled.li`
  font-size: 1.25rem;
  font-weight: 500;
 
  @media ${media.tablet} {
     flex:40%;
  }
  
  ::before {
    content: '';
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-radius: 50%;
    margin-right: 10px;
    background-color: black;
  }
`;
