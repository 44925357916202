import Button from 'components/Button';
import React, { useState } from 'react'
import styled from 'styled-components';
import ScheduleCard from './ScheduleCard';
import media from 'utils/media';
import { scheduleData } from 'data/scheduledata';

const ScheduleSection = ({selectedSchedule,setSelectedSchedule,setSubmitted}) => {
    return (
        <Section>
            <div className='upper--region'>
                <GradientH1>
                    We’d love to collaborate!
                </GradientH1>
                <H1>
                    Let us know what you’re after.
                </H1>
            </div>
            <div className='bottom--region'>
                <ConsultContainer>
                    {scheduleData.map((sd)=>{
                        return(

                            <ScheduleCard
                                title={sd.title}
                                details={sd.desc}
                                logo={sd.icon}
                                selectedSchedule={selectedSchedule}
                                setSelectedSchedule={setSelectedSchedule}
                            />
                        )
                    })}
            
                </ConsultContainer>
                <div style={{ textAlign: 'center' }}>
                    <Button value={"Submit"} inversed={true}
                        style={{
                            width: '50%',
                            margin: '0 auto'
                        }}
                        onClick={()=>{
                            // alert(`you selected ${selectedSchedule}`);
                            setSubmitted(true)
                        }}
                    />

                </div>
            </div>
        </Section>
    )
}

export default ScheduleSection;

const ConsultContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  margin: 0 5%;
  margin-bottom: 8%;

  @media ${media.tablet} {
  
    >div:first-child{
    width: 100%;
    aspect-ratio: 4.5;
    .contact--icon > div{
        height:2.2em;
    }
    .contact--details{
        /* justify-content: flex-start; */
        justify-content: center;
        align-items: center;
        h1,p{
            text-align: center;
        }
    }
    h1{
        font-size: 1.1rem;
    }
    p{
        font-size: 1rem;
        width: 75%;
    }
  } 
  }


   /* > div{
    flex:1 1 40%;
   } */
   
  @media ${media.tablet} {
      margin: 0;
      margin-bottom: 8%;
      min-height: 10rem;
      gap:1rem;
      
  }
`;

const Section = styled.section`
height: 100%;
padding: 4% 5%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
gap: 2rem;

@media ${media.tablet} {
    .upper--region{
        flex:1 1 35%;
    }
    .bottom--region{
        flex: 1 1 65%;
    }

}


.upper--region{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}


`;

const GradientH1 = styled.h1`
font-size: 2rem;
font-weight: 600;
background: radial-gradient(50% 50% at 50% 50%, #38B450 0%, #0CBEE6 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-align: center;
`;

const H1 = styled.h1`
font-size: 2rem;
font-weight: 600;
text-align: center;
`;